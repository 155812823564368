import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Link from './Link';
import InvertLink from './InvertLink';
import FeaturedLinkTitle from './FeaturedLinkTitle';
import FeaturedLinkTile from './FeaturedLinkTile';

import sanityImage from '../util/sanityImage';
import useHover from '../util/useHover';
import cx from 'classnames';

const FeaturedLinkFullWidth = ({
	multiLinkDesc,
	customImage,
	invert,
	slug,
	pageSlug,
	linkType,
	title,
	tileTitle,
	artist,
	exhibitionLocation,
	exhibitionLocationOther,
	hero,
	image,
	heroImage,
	subtitle,
	index,
	startDate,
	endDate,
	...props
}) => {
	const picture = customImage || image || hero || heroImage;

	let destination;

	switch (linkType) {
		case 'exhibition':
			destination = `/exhibitions/${slug}`;
			break;
		case 'artist':
			destination = `/artists/${slug}`;
			break;
		case 'site':
			destination = `/exhibitions/site-${slug}`;
			break;
		case 'anatomy':
			destination = `/exhibitions/anatomy-${slug}`;
			break;
		case 'queue':
			destination = `/exhibitions/queue-${slug}`;
			break;
		case 'alliance':
			destination = `/alliance/${slug}`;
			break;
		case 'project':
			destination = `/projects/${slug}`;
			break;
		case 'page':
			destination = `/${pageSlug}`;
			break;

		default:
			destination = `/`;
			break;
	}

	const [hoverRef, isHovered] = useHover();

	if (invert) {
		return (
			<section
				className={cx('featured-link featured-link--full', {
					' ': index > 0,
				})}
			>
				<InvertLink {...props} />
			</section>
		);
	}

	return (
		<section
			ref={hoverRef}
			className={cx(
				'featured-link featured-link--full info-box-container mb5 mb10--md mb10--lg',
				{
					// 'mt5 mt10--md mt20--lg': index > 0,
				}
			)}
		>
			<Link url={destination}>
				<picture
					className={cx('db x artwork--full-container', {
						'bt--black': index > 0,
					})}
				>
					<source
						srcSet={sanityImage(picture, { w: 2000 })}
						media="(min-width: 1400px)"
					/>
					<source
						srcSet={sanityImage(picture, { w: 1600 })}
						media="(min-width: 1200px)"
					/>
					<source
						srcSet={sanityImage(picture, { w: 1400 })}
						media="(min-width: 1000px)"
					/>
					<source
						srcSet={sanityImage(picture, { w: 1200 })}
						media="(min-width: 600px)"
					/>
					<img
						alt={picture.alt || title}
						className={cx('db artwork--full', {
							hovered: isHovered,
						})}
						src={sanityImage(picture, { w: 1200 })}
					/>
				</picture>
				<aside className="mt2 info-box  jcb ais pl2 pr2 pl5--lg pr5--lg grid-container contained">
					<FeaturedLinkTitle
						linkType={linkType}
						title={title}
						artist={artist}
						exhibitionLocation={exhibitionLocation}
						exhibitionLocationOther={exhibitionLocationOther}
						endDate={endDate}
						startDate={startDate}
						subtitle={subtitle}
					/>
					
					{multiLinkDesc && (
					<div className="df jcb m450 mb2 fdc ais multiLinkDesc">
						<BlockContent blocks={multiLinkDesc} serializers={richText} />
					</div>
					)}
						
					<FeaturedLinkTile
						linkType={linkType}
						tileTitle={tileTitle}
					/>
				</aside>
			</Link>
		</section>
	);
};

export default FeaturedLinkFullWidth;
