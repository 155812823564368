import React, { Component } from 'react';
import ReactHowler from 'react-howler'
import raf from 'raf'

import Play from './icons/Play';
import Pause from './icons/Pause';

class AudioPlayer extends Component {
	constructor (props) {
		super(props)

		this.state = {
			playing: false,
			loaded: false,
			loop: false,
			mute: false,
			volume: 1.0,
			seek: 0.0,
			isSeeking: false
		}

		this.handleToggle = this.handleToggle.bind(this)
		this.handleOnLoad = this.handleOnLoad.bind(this)
		this.handleOnEnd = this.handleOnEnd.bind(this)
		this.handleOnPlay = this.handleOnPlay.bind(this)
		this.handleStop = this.handleStop.bind(this)
		this.renderSeekPos = this.renderSeekPos.bind(this)
		this.handleMouseDownSeek = this.handleMouseDownSeek.bind(this)
		this.handleMouseUpSeek = this.handleMouseUpSeek.bind(this)
		this.handleSeekingChange = this.handleSeekingChange.bind(this)
	}

	componentWillUnmount () {
		this.clearRAF()
	}

	handleToggle () {
		this.setState({
			playing: !this.state.playing
		})
	}

	handleOnLoad () {
		this.setState({
			loaded: true,
			duration: this.player.duration()
		})
	}

	handleOnPlay () {
		this.setState({
			playing: true
		})
		this.renderSeekPos()
	}

	handleOnEnd () {
		this.setState({
			playing: false
		})
		this.clearRAF()
	}

	handleStop () {
		this.player.stop()
		this.setState({
			playing: false // Need to update our local state so we don't immediately invoke autoplay
		})
		this.renderSeekPos()
	}

	handleMouseDownSeek () {
		this.setState({
			isSeeking: true
		})
	}

	handleMouseUpSeek (e) {
		this.setState({
			isSeeking: false
		})

		this.player.seek(e.target.value)
	}

	handleSeekingChange (e) {
		this.setState({
			seek: parseFloat(e.target.value)
		})
	}

	renderSeekPos () {
		if (!this.state.isSeeking) {
			this.setState({
			seek: this.player.seek()
			})
		}
		if (this.state.playing) {
			this._raf = raf(this.renderSeekPos)
		}
	}

	clearRAF () {
		raf.cancel(this._raf)
	}

	render() {
		const { title, originalFilename, url } = this.props

		return (
			<div className="audio-player df">
				<ReactHowler
					src={[url]}
					playing={this.state.playing}
					onLoad={this.handleOnLoad}
					onPlay={this.handleOnPlay}
					onEnd={this.handleOnEnd}
					volume={this.state.volume}
					ref={ref => (this.player = ref)}
				/>
				<button
					onClick={() =>
						this.setState({
							playing: !this.state.playing,
						})
					}
					className="button--audio-player df fdc jcc"
				>
					{this.state.playing ? (
						<Pause className="button--audio-player-icon" />
					) : (
						<Play className="button--audio-player-icon" />
					)}
				</button>
				<div className="audio-player__info p1 p2--lg df aic x">
					<p className=" ">{title || originalFilename}</p>
					<p className=" audio-player__time">
						<span>{`${Math.floor(
							(this.state.seek.toFixed(2) / 60) % 60
						)}:${
							Math.floor(this.state.seek.toFixed(2) % 60) < 10
								? '0' +
								  Math.floor(this.state.seek.toFixed(2) % 60)
								: Math.floor(this.state.seek.toFixed(2) % 60)
						}`}</span>

						<span> | </span>
						<span>
							{this.state.duration
								? `${Math.floor(
										(this.state.duration.toFixed(2) / 60) %
											60
								  )}:${Math.floor(
										this.state.duration.toFixed(2) % 60
								  )}`
								: ''}
						</span>
					</p>
				</div>
			</div>
		);
	}
};

export default AudioPlayer;
