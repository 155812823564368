import React from 'react';

export default ({ image }) => (
	<picture>
		<source
			srcSet={`${image.url}?w=2000&auto=format&q=100`}
			media="(min-width: 1200px)"
		/>
		<source
			srcSet={`${image.url}?w=1200&auto=format&q=100`}
			media="(min-width: 1000px)"
		/>
		<source
			srcSet={`${image.url}?w=800&auto=format`}
			media="(min-width: 600px)"
		/>
		<img
			alt={image.alt || 'Image courtesy of the artist'}
			className="db x bt--black bb--black"
			src={`${image.url}?w=600&auto=format`}
		/>
	</picture>
);
