import React from 'react';

import cx from 'classnames';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

const PageLocationInfo = ({ pageLocationDetails, pageLocationContent, index }) => (
	<section className="grid-container contained mt5 mt10--md mt15--lg">
		<div className="row">
			<div className="col c5--md">
				<div className="sans--18 sans--24--md rich-text line-break rich-text-with-heading">
					<BlockContent blocks={pageLocationDetails} serializers={richText} />
				</div>
			</div>
			<div className="col c7--md mt10 mt0--lg">
				<div className="sans--18 sans--24--md line-break rich-text">
					<BlockContent blocks={pageLocationContent} serializers={richText} />
				</div>
			</div>
		</div>
	</section>
);

export default PageLocationInfo;
