import React from 'react';

import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export default ({ embed, description }) => {
	return (
		<section className="mt5 mt10--md mb5 mb10--md mt20--lg mb20--lg  grid-container contained">
			<div className="df fw row">
				<aside className="col c5--lg ">
					<div
						className="spotify"
						dangerouslySetInnerHTML={{ __html: embed }}
					/>
				</aside>
				<div className="col c1--lg show--lg" />
				<div className="col c6--lg mt5 mt0--lg">
					{description && (
						<div className="sans--18 sans--24--md rich-text">
							<BlockContent
								blocks={description}
								serializers={richText}
							/>
						</div>
					)}
				</div>
			</div>
		</section>
	);
;
};
