import React from 'react';
import Link from './Link';
import sanityImage from '../util/sanityImage';

export default ({
	customImage,
	slug,
	pageSlug,
	linkType,
	title,
	artist,
	hero,
	image,
	heroImage,
	startDate,
	endDate,
	url,
}) => {
	const picture = customImage || image || hero || heroImage;

	return (
		<Link
			className="featured-link--invert pr db b--black"
			url={url || slug}
		>
			<picture>
				<source
					srcSet={sanityImage(picture, {
						w: 2000,
					})}
					media="(min-width: 1400px)"
				/>
				<source
					srcSet={sanityImage(picture, {
						w: 1600,
					})}
					media="(min-width: 1200px)"
				/>
				<source
					srcSet={sanityImage(picture, {
						w: 1400,
					})}
					media="(min-width: 1000px)"
				/>
				<source
					srcSet={sanityImage(picture, {
						w: 1200,
					})}
					media="(min-width: 600px)"
				/>
				<img
					alt={
						picture.alt ||
						artist ||
						title ||
						'Image courtesy of the artist'
					}
					className="db featured-link--invert__image"
					src={sanityImage(picture, { w: 1200 })}
				/>
			</picture>
			<div className="pa fill df fdc jcb p2">
				<div className="x">
					<p className="sans--24 sans--36--md sans--48--lg">
						{artist}
					</p>
					<p className="sans--14 sans--18--md">
						{title}
						{endDate && startDate && (
							<React.Fragment>
								<br />
								<span className="sans--14 sans--18--md">
									({startDate} — {endDate})
								</span>
							</React.Fragment>
						)}
					</p>
				</div>
				<p className="tile uppercase fit">
					{linkType === 'exhibition' && 'Exhibition'}
					{linkType === 'site' && 'SITE'}
					{linkType === 'project' && 'Project'}
					{linkType === 'anatomy' && 'Anatomy'}
				</p>
			</div>
		</Link>
	);
};
