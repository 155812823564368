import React from 'react';

// Home Components
import FeaturedLinkFullWidth from './FeaturedLinkFullWidth';
import FeaturedLinkSingle from './FeaturedLinkSingle';
import FeaturedLinksMulti from './FeaturedLinksMulti';
import BigText from './BigText';
import Link from './Link';
import Btn from './Btn';
import NewTitleSection from './NewTitleSection';

// Exhibition Components
import FullWidthImage from './FullWidthImage';
import ExhibitionRichText from './ExhibitionRichText';
import PullQuote from './PullQuote'
import ArtworkDouble from './ArtworkDouble';
import ArtworkSingle from './ArtworkSingle';
import MultimediaSection from './MultimediaSection';
import VideoPlayer from './VideoPlayer';
import SiteLocationIntro from './SiteLocationIntro';
import SiteLocationSection from './SiteLocationSection';
import SpotifyPlaylist from './SpotifyPlaylist';
import StudioGallery from './StudioGallery';
import MasonryGrid from './MasonryGrid';
import PageRichText from './PageRichText';
import AudioPlayer from './AudioPlayer';
import PageAudioPlayer from './PageAudioPlayer';
import SectionCover from './SectionCover';
import SectionTitle from './SectionTitle';
import PageLocationInfo from './PageLocationInfo';
import PageContactList from './PageContactList';

const isProduction = process.env.NODE_ENV !== 'development';

export default ({ components = [] }) => (
	<React.Fragment>
		{components && (
			<React.Fragment>
				{components.map((component, index) => {
					switch (component._type) {
						case 'featuredLinkFullWidth':
							return (
								<FeaturedLinkFullWidth
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'featuredLinkSingle':
							return (
								<FeaturedLinkSingle
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'featuredLinkMulti':
							return (
								<FeaturedLinksMulti
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'link':
							return (
								<Link
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'btn':
							return (
								<Btn
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'newTitleSection':
							return (
								<NewTitleSection
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'bigText':
							return (
								<BigText
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'richText':
							return (
								<ExhibitionRichText
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'pullQuote':
							return (
								<PullQuote
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'fullWidthImage':
							return (
								<FullWidthImage
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'artworkDouble':
						case 'artworkImageDouble':
						case 'selectedArtworkDouble':
							return (
								<ArtworkDouble
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'artworkImageSingle':
						case 'selectedArtworkSingle':
							return (
								<ArtworkSingle
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'multimediaSection':
							return (
								<MultimediaSection
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'externalVideo':
							return (
								<VideoPlayer
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'locationIntro':
							return (
								<SiteLocationIntro
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'locationSection':
							return (
								<SiteLocationSection
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'spotify':
							return (
								<SpotifyPlaylist
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'studioGallery':
							return (
								<MasonryGrid
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'grid':
						case 'imageGrid':
							return (
								<React.Fragment>
									{component.artwork.map((section, index) => {
										return section._type ===
											'selectedArtworkDouble' ||
											section._type ===
												'artworkImageDouble' ? (
											<ArtworkDouble
												index={index}
												key={section._key}
												{...section}
											/>
										) : (
											<ArtworkSingle
												index={index}
												key={section._key}
												{...section}
											/>
										);
									})}
								</React.Fragment>
							);
						case 'richTextPage':
							return (
								<PageRichText
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'locationInfoPage':
							return (
								<PageLocationInfo
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'contactListPage':
							return (
								<PageContactList
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'audioPlayer':
							return (
								<PageAudioPlayer
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'sectionTitle':
							return (
								<SectionTitle
									index={index}
									key={component._key}
									{...component}
								/>
							);
						case 'sectionCover':
							return (
								<SectionCover
									index={index}
									key={component._key}
									{...component}
								/>
							);
						default:
							console.log(!isProduction && component);
							return (
								<React.Fragment>
									{!isProduction && (
										<p className="mt2 mb2 sans--24">
											Missing type : {component._type}
										</p>
									)}
								</React.Fragment>
							);
					}
				})}
			</React.Fragment>
		)}
	</React.Fragment>
);

