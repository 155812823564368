import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

import cx from 'classnames';

const BigText = ({ description, index }) => (
	<section
		className={cx(
			'grid-container contained mt5 mt10--md mb5 mb10--md mb20--lg',
			{
				'mt20--lg': index > 0,
			}
		)}
	>
		<div className="contained--left sans--24 sans--48--md sans--64--lg">
			<BlockContent blocks={description} serializers={richText} />
		</div>
	</section>
);
export default BigText;
