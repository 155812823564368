import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Link from './Link';
import InvertLink from './InvertLink';
import FeaturedLinkTitle from './FeaturedLinkTitle';
import FeaturedLinkTile from './FeaturedLinkTile';

import sanityImage from '../util/sanityImage';
import cx from 'classnames';

const FeaturedLinksMulti = ({ title, list, index, ...props }) => {
	return (
		<section
			className={cx(
				'featured-link featured-link--multi grid-container contained mb5 mb10--md mb10--lg',
				{
					' ': index > 0,
				}
			)}
		>
			{title && (
				<p className="sans--24 sans--36--md sans--48--lg mb2 mb5--md">
					{title}
				</p>
			)}
			<div className="df fw row">
				{list.map(
					(
						{
							_key,
							size,
							multiLinkDesc,
							invert,
							customImage,
							slug,
							pageSlug,
							linkType,
							title,
							tileTitle,
							artist,
							exhibitionLocation,
							exhibitionLocationOther,
							hero,
							image,
							heroImage,
							subtitle,
							startDate,
							endDate,
						},
						index
					) => {
						const picture =
							customImage || image || hero || heroImage;

						let destination;

						switch (linkType) {
							case 'exhibition':
								destination = `/exhibitions/${slug}`;
								break;
							case 'artist':
								destination = `/artists/${slug}`;
								break;
							case 'site':
								destination = `/exhibitions/site-${slug}`;
								break;
							case 'anatomy':
								destination = `/exhibitions/anatomy-${slug}`;
								break;
							case 'queue':
								destination = `/exhibitions/queue-${slug}`;
								break;
							case 'alliance':
								destination = `/alliance/${slug}`;
								break;
							case 'project':
								destination = `/projects/${slug}`;
								break;
							case 'page':
								destination = `/${pageSlug}`;
								break;

							default:
								break;
						}

						return (
							<article
								key={_key}
								className={cx(
									`featured-link featured-link--${size} col`,
									{
										'mt5 mt0--md': index > 0,
										'c6--md': size === 'half',
										'c4--md': size === 'third',
										'c3--md': size === 'quarter',
									}
								)}
							>
								{invert ? (
									<InvertLink {...link} />
								) : (
									<Link
										className="df fdc y"
										url={destination}
									>
										<picture className="db b--black image--tile x">
											<source
												srcSet={sanityImage(picture, {
													w: 2000,
												})}
												media="(min-width: 1400px)"
											/>
											<source
												srcSet={sanityImage(picture, {
													w: 1600,
												})}
												media="(min-width: 1200px)"
											/>
											<source
												srcSet={sanityImage(picture, {
													w: 1400,
												})}
												media="(min-width: 1000px)"
											/>
											<source
												srcSet={sanityImage(picture, {
													w: 1200,
												})}
												media="(min-width: 600px)"
											/>
											<img
												alt={
													picture.alt ||
													title ||
													artist
												}
												className="db featured-link--image y"
												src={sanityImage(picture, {
													w: 1200,
												})}
											/>
										</picture>
										<aside className={cx("mt2 df jcb ais", {
											'hideDefaultTile': multiLinkDesc
										})}>
											<FeaturedLinkTitle
												linkType={linkType}
												title={title}
												artist={artist}
												exhibitionLocation={exhibitionLocation}
												exhibitionLocationOther={exhibitionLocationOther}
												endDate={endDate}
												startDate={startDate}
												subtitle={subtitle}
											/>
											<FeaturedLinkTile
												linkType={linkType}
												tileTitle={tileTitle}
											/>
										</aside>
										{multiLinkDesc && (
										<div className="df jcb fdc ais multiLinkDesc">
											<BlockContent blocks={multiLinkDesc} serializers={richText} />
											
										{( linkType === 'page') && (	
										<span className="multiLinkTile tile text-center sans--16">Learn More</span>
										)}
											
										{(linkType !== 'page') && (	
										<FeaturedLinkTile
											linkType={linkType}
											tileTitle={tileTitle}
										/>
										)}
											
										</div>
										)}
									</Link>
								)}
							</article>
						);
					}
				)}
			</div>
		</section>
	);
};
export default FeaturedLinksMulti;
