import React from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

export default ({ list = [] }) => (
	<section className="grid-container contained mt5 mb5 mt10--md mb10--lg mt20--lg mb20--lg">
		<ResponsiveMasonry
			columnsCountBreakPoints={{ 350: 2, 800: 2, 1000: 3 }}
		>
			<Masonry gutter="1rem">
				{list.map(item => (
					<img key={item._key} src={`${item.url}?auto=format`} />
				))}
			</Masonry>
		</ResponsiveMasonry>
	</section>
);
