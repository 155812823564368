import React from 'react';

import cx from 'classnames';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

const PageContactList = ({ contacts = [] }) => (
	<section className="grid-container contained mt5 mt10--md mt15--lg mb5 mb10--md mb15--lg">
		<div className="row">
            <div className="col c12--md">
                <ul className="row df fw">
                    {contacts.map((contact, index) => (
                        <li
                            key={contact._key}
                            className={cx('col c12 c12--xs c4--md mt2 mt0--md', {
                                'mt2 mt5--md': index > 2,
                            })}
                        >
                            <p className="sans--18 sans--24--md mb1">
                                {contact.name}
                            </p>
                            {contact.position && (
                                <p className="sans--14 sans--18--md">
                                    {contact.position}
                                </p>
                            )}
                            {contact.email && (
                                <a
                                    href={`mailto:${contact.email}`}
                                    className="db link--underline-reverse fit sans--14 sans--18--md"
                                >
                                    {contact.email}
                                </a>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
		</div>
	</section>
);

export default PageContactList;
