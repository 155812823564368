import React from 'react';

export default props => (
  <svg
    {...props}
    width="16"
    height="47"
    viewBox="0 0 16 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.00672 1C0.759914 8.5 7.34267 23.5 16 23.5" stroke="black" />
    <path d="M1.00672 46C0.759914 38.5 7.34267 23.5 16 23.5" stroke="black" />
  </svg>
);
