import React, { useContext } from 'react';
import AudioPlayer from './AudioPlayer';
import Context from '../context/Context';

import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import isEmpty from 'lodash/isEmpty';

export default ({ audio, description, image, detailShot }) => {
	const { setLightBoxOpen, setFeaturedImage } = useContext(Context);
	return (
		<section className="grid-container contained mt5 mt10--md mb5 mb10--md mb20--lg ">
			<div className="df fw row">
				{!isEmpty(image) && (
					<figure className="col c6--lg">
						<picture>
							<source
								srcSet={`${image.artwork.url}?w=2000&auto=format&q=100`}
								media="(min-width: 1200px)"
							/>
							<source
								srcSet={`${image.artwork.url}?w=1200&auto=format&q=100`}
								media="(min-width: 1000px)"
							/>
							<source
								srcSet={`${image.artwork.url}?w=800&auto=format`}
								media="(min-width: 600px)"
							/>
							<img
								alt={image.title || 'Library Street Collective'}
								className="db x b--black pointer"
								onClick={() => {
									setFeaturedImage(image);
									setTimeout(() => {
										setLightBoxOpen(true);
									}, 100);
								}}
								src={`${image.artwork.url}?w=600&auto=format`}
							/>
						</picture>
						<figcaption className="mt1 mt2--lg">
							{image.title && (
								<p className="sans--14 sans--18--md">
									{image.title}
								</p>
							)}
							{image.description && (
								<div className="sans--14 sans--18--md rich-text line-break">
									<BlockContent
										blocks={image.description}
										serializers={richText}
									/>
								</div>
							)}
						</figcaption>
					</figure>
				)}

				{(!isEmpty(description) ||
					!isEmpty(audio) ||
					!isEmpty(detailShot)) && (
					<div className="col c6--lg ">
						{!isEmpty(detailShot) && (
							<figure className="">
								<picture>
									<source
										srcSet={`${detailShot.artwork.url}?w=2000&auto=format&q=100`}
										media="(min-width: 1200px)"
									/>
									<source
										srcSet={`${detailShot.artwork.url}?w=1200&auto=format&q=100`}
										media="(min-width: 1000px)"
									/>
									<source
										srcSet={`${detailShot.artwork.url}?w=800&auto=format`}
										media="(min-width: 600px)"
									/>
									<img
										alt={
											detailShot.title ||
											'Library Street Collective'
										}
										className="db x b--black pointer"
										onClick={() => {
											setFeaturedImage(detailShot);
											setTimeout(() => {
												setLightBoxOpen(true);
											}, 100);
										}}
										src={`${detailShot.artwork.url}?w=600&auto=format`}
									/>
								</picture>
								<figcaption className="mt1 mt2--lg">
									{detailShot.title && (
										<p className="sans--14 sans--18--md">
											{detailShot.title}
										</p>
									)}
									{detailShot.description && (
										<div className="sans--14 sans--18--md rich-text line-break">
											<BlockContent
												blocks={detailShot.description}
												serializers={richText}
											/>
										</div>
									)}
								</figcaption>
							</figure>
						)}
						{!isEmpty(description) && (
							<div className=" sans--14 sans--18--md sans--24--lg line-break rich-text ">
								<BlockContent blocks={description} />
							</div>
						)}
						{!isEmpty(audio) && <AudioPlayer {...audio} />}
					</div>
				)}
			</div>
		</section>
	);
};
