import React, { Fragment } from 'react';
import ExhibitionRichText from './ExhibitionRichText';

import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import sanityImage from '../util/sanityImage';
import ArrowRight from './icons/ArrowRight';

import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

export default ({ description, image, gallery = [] }) => {
	const [sliderRef, slider] = useKeenSlider({
		slidesPerView: 1,
		loop: true,
		spacing: 50,
	});

	const locationCarousel = gallery && gallery.length > 0 && (
		<div className="col c5--lg psy--lg top pt10--lg pr">
			<div className="grid-container contained">
				<div
					ref={sliderRef}
					className="keen-slider featured__slider df "
				>
					{gallery.map(img => {
						return (
							<div
								key={img._key}
								className="keen-slider__slide carousel__slide"
							>
								<picture>
									<source
										srcSet={sanityImage(img.artwork.url, {
											w: 1200,
										})}
										media="(min-width: 600px)"
									/>
									<img
										alt={img?.alt || img.artwork.title}
										className="db x b--black"
										src={sanityImage(img.artwork.url, {
											w: 1200,
										})}
									/>
								</picture>
								{img.artwork.title && (
									<figcaption className="sans--12 sans--14--lg mt2">
										{img.artwork.title}
									</figcaption>
								)}
								{img.artwork.description && (
									<figcaption className="sans--12 sans--14--lg mt2">
										<BlockContent
											blocks={img.artwork.description}
											serializers={richText}
										/>
									</figcaption>
								)}
							</div>
						);
					})}
				</div>
			</div>
			{slider && (
				<Fragment>
					<button
						className="slider__button slider__button--prev pa"
						onClick={e => e.stopPropagation() || slider.prev()}
					>
						<ArrowRight className="icon icon--vertical icon--slider flip" />
					</button>
					<button
						className="slider__button slider__button--next pa"
						onClick={e => e.stopPropagation() || slider.next()}
					>
						<ArrowRight className="icon icon--vertical icon--slider" />
					</button>
				</Fragment>
			)}
		</div>
	);

	const locationImage = image && !gallery && (
		<figure className="col c5--lg psy--lg top pt10--lg">
			<picture>
				<source
					srcSet={`${image.artwork.url}?w=2000&auto=format&q=100`}
					media="(min-width: 1200px)"
				/>
				<source
					srcSet={`${image.artwork.url}?w=1200&auto=format&q=100`}
					media="(min-width: 1000px)"
				/>
				<source
					srcSet={`${image.artwork.url}?w=800&auto=format`}
					media="(min-width: 600px)"
				/>
				<img
					alt={image?.title || image.title}
					className="db x b--black"
					src={`${image.artwork.url}?w=600&auto=format`}
				/>
			</picture>
			{image.title && (
				<figcaption className="sans--12 sans--14--lg mt2">
					{image.title}
				</figcaption>
			)}
			{image.description && (
				<figcaption className="sans--12 sans--14--lg mt2">
					<BlockContent
						blocks={image.description}
						serializers={richText}
					/>
				</figcaption>
			)}
		</figure>
	);

	const showImages = !!image || !!gallery;

	return showImages ? (
		<div className="mt5 mt10--md mb5 mb10--md mb20--lg">
			<div className="row">
				{locationCarousel || locationImage}
				<div className="col c1--lg show--lg pt5--md pt10--lg" />
				<div className="col c6--lg pt5 pt10--lg">
					<div className=" sans--18 sans--24--md line-break rich-text ">
						<BlockContent
							blocks={description}
							serializers={richText}
						/>
					</div>
				</div>
			</div>
		</div>
	) : (
		<ExhibitionRichText content={description} />
	);
};
