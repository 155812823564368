import React from 'react';

import cx from 'classnames';
import AudioPlayer from './AudioPlayer';

const PageAudioPlayer = ({ title, audio, index }) => (
	<section
		className={cx('grid-container contained mb5 mb10--md mb20--lg', {
			'mt5 mt10--md mt20--lg': index > 0,
		})}
	>
		<div className="row align--center mt5 mt10--lg">
			<div className="col c10--md c9--lg c7--xl">
				<p className="sans--18 sans--24--md mb2 mb5--md tc">{title}</p>
				<AudioPlayer title={title} url={audio.url} />
			</div>
		</div>
	</section>
);

export default PageAudioPlayer;
