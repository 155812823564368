import React from 'react';

import cx from 'classnames';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

const PageRichText = ({ content, index }) => (
	<section
		className={cx('grid-container contained mb5 mb10--md mb20--lg', {
			'mt5 mt10--md mt20--lg': index > 0,
		})}
	>
		<div className="row align--left mt5 mt10--lg">
			<div className="col c6--md c6--lg c6--xl">
				<div className="sans--18 sans--24--md line-break rich-text rich-text--page">
					<BlockContent blocks={content} serializers={richText} />
				</div>
			</div>
		</div>
	</section>
);

export default PageRichText;
