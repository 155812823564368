import React from 'react';

export default props => (
	<svg {...props} x="0px" y="0px" viewBox="0 0 42 42">
		<title>Pause Icon</title>
		<g>
			<path d="M14.5,0c-0.552,0-1,0.447-1,1v40c0,0.553,0.448,1,1,1s1-0.447,1-1V1C15.5,0.447,15.052,0,14.5,0z" />
			<path d="M27.5,0c-0.552,0-1,0.447-1,1v40c0,0.553,0.448,1,1,1s1-0.447,1-1V1C28.5,0.447,28.052,0,27.5,0z" />
		</g>
	</svg>
);
