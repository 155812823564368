import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export default ({ title, descriptionsss }) => (
	<section className="page-big-text new-title-section p2 mb3 bb--dark-brown">
		<h2 className="sans--24 sans--24--md sans--32--lg text-center">{title}</h2>
		{descriptionsss && (
		<BlockContent blocks={descriptionsss} serializers={richText} />
		)}
	</section>
);
