import React from 'react';
import { Link } from 'gatsby';

export default ({
  _type,
  title,
  url,
  product,
  slug,
  openInNewWindow,
  productName,
  collectionName,
  children,
  linkType,
  ...props
}) => {
	return (
	<div className="mb5 mb10--md mb20--lg grid-container contained">
		<a
			{...props}
			title={title}
			target={openInNewWindow ? '_blank' : null}
			rel={openInNewWindow ? 'noopener noreferrer' : null}
			href={url}
			className=" tc x db tile--large"
		>
			<span className="sans--24 sans--32--md sans--32--lg">
				{children || title}
			</span>
		</a>
	</div>
		);
};
